<template>
  <modify-apply-block
    :column-list="columnList"
    :display="display"
    data-type="worker_edu"
    :table-actions="tableActions"
    ref="worker_edu_p"
    :getEdit="getEdit"
    :saveEdit="saveEdit"
    :addEdit="addEdit"
    :form-parms-add="formParmsAdd"
    block-name="教育经历"
    :is-real="isReal"
    :is-absolut="isAbsolut"
    @completeness="completeness"
    ref-name="worker_edu"
    :worker-id="workerId">
  </modify-apply-block>
</template>

<script>
import ModifyApplyBlock from './modifyApplyBlock'

import {
  workerEduRequest
} from '@/api'

export default {
  components: {
    ModifyApplyBlock
  },
  props: {
    isReal: {
      type: Boolean,
      default: false
    },
    isAbsolut: {
      type: Boolean,
      default: true
    },
    workerId: {
      type: [String, Number]
    },
    display: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    async getEdit () {
      if (this.isReal) {
        let data = await workerEduRequest.get({
          workerId: this.workerId
        })
        return data.map(v => {
          return {
            sourceInfo: v
          }
        })
      } else {
        return await workerEduRequest.getEdit({
          workerId: this.workerId
        })
      }
    },
    async saveEdit (parm) {
      if (this.isReal) {
        if (parm.isDel) {
          return await workerEduRequest.del(parm.id)
        } else {
          return await workerEduRequest.update(parm.id, parm)
        }
      } else {
        return await workerEduRequest.saveEdit(Object.assign({}, parm, {
          dataId: parm.id,
          id: undefined
        }))
      }
    },
    async addEdit (parm) {
      if (this.isReal) {
        return await workerEduRequest.add(parm)
      } else {
        return await workerEduRequest.addEdit(parm)
      }
    },
    completeness (data) {
      this.$emit('completeness', data)
    },
    async save () {
      await this.$refs['worker_edu_p'].save()
    },
    async submit () {
      await this.$refs['worker_edu_p'].submit()
    },
    testEdited () {
      return this.$refs['worker_edu_p'].testEdited()
    }
  },
  computed: {
    tableActions () {
      return [{
        key: 'draft_file',
        label: '扫描件',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'table-expand-edit',
        label: '修改',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'del',
        label: '删除',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'back_del',
        label: '恢复',
        show: (data) => {
          return data.isDel
        }
      }]
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          label: '学历',
          selectDatas: this.$store.getters.selectDatas['worker_education_list'],
          key: 'edu',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '学位',
          selectDatas: this.$store.getters.selectDatas['worker_edu_degree'],
          key: 'degree'
        },
        {
          type: 'input',
          label: '毕业院校',
          key: 'school'
        },
        {
          type: 'input',
          label: '学校编码',
          key: 'schoolCode'
        },
        {
          type: 'input',
          label: '专业',
          key: 'pro'
        },
        {
          type: 'datePicker',
          label: '入学时间',
          key: 'startDate'
        },
        {
          type: 'datePicker',
          label: '毕业时间',
          key: 'endDate'
        },
        {
          type: 'input',
          label: '学制',
          key: 'xz'
        },
        {
          type: 'input',
          label: '证书编号',
          key: 'zsCode'
        },
        {
          type: 'select',
          label: '教育形式',
          selectDatas: this.$store.getters.selectDatas['worker_edu_type_list'],
          key: 'jyxs'
        },
        {
          type: 'select',
          label: '学历类型',
          selectDatas: [{key: '第一学历', label: '第一学历'}, {key: '最高学历', label: '最高学历'}, {key: '普通学历', label: '普通学历'}, {key: '最高学历兼第一学历', label: '最高学历兼第一学历'}],
          key: 'type'
        }]
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '学历',
          field: 'edu',
          sort: true
        },
        {
          title: '学位',
          field: 'degree',
          sort: true
        },
        {
          title: '毕业院校',
          field: 'school',
          sort: true
        },
        {
          title: '学校编码',
          field: 'schoolCode',
          sort: true
        },
        {
          title: '专业',
          field: 'pro',
          sort: true
        },
        {
          title: '入学时间',
          field: 'startDate',
          sort: true,
          dataType: Date,
          format: (rowData) => {
            return rowData && rowData.startDate ? rowData.startDate.slice(0, 10) : '-'
          }
        },
        {
          title: '毕业时间',
          field: 'endDate',
          sort: true,
          dataType: Date,
          format: (rowData) => {
            return rowData && rowData.endDate ? rowData.endDate.slice(0, 10) : '-'
          }
        },
        {
          title: '学制',
          field: 'xz',
          sort: true
        },
        {
          title: '证书编号',
          field: 'zsCode',
          sort: true
        },
        {
          title: '教育形式',
          field: 'jyxs',
          sort: true
        },
        {
          title: '学历类型',
          field: 'type',
          sort: true
        }]
        return data
      }
    }
  }
}
</script>

<template>
  <modify-apply-block
    :column-list="columnList"
    ref="worker_job_title_p"
    :display="display"
    :form-parms-add="formParmsAdd"
    block-name="职称聘任记录"
    :isReal="isReal"
    :getEdit="getEdit"
    :saveEdit="saveEdit"
    :addEdit="addEdit"
    :deal-data-info="dealDataInfo"
    @completeness="completeness"
    ref-name="worker_job_title"
    :worker-id="workerId"
    :is-absolut="isAbsolut">
  </modify-apply-block>
</template>

<script>
import ModifyApplyBlock from './modifyApplyBlock'

import {
  workerJobTitleRequest
} from '@/api'

export default {
  components: {
    ModifyApplyBlock
  },
  props: {
    isReal: {
      type: Boolean,
      default: false
    },
    isAbsolut: {
      type: Boolean,
      default: true
    },
    workerId: {
      type: [String, Number]
    },
    display: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    async getEdit () {
      if (this.isReal) {
        let data = await workerJobTitleRequest.get({
          workerId: this.workerId
        })
        return data.map(v => {
          return {
            sourceInfo: v
          }
        })
      } else {
        return await workerJobTitleRequest.getEdit({
          workerId: this.workerId
        })
      }
    },
    async saveEdit (parm) {
      if (this.isReal) {
        if (parm.isDel) {
          return await workerJobTitleRequest.del(parm.id)
        } else {
          return await workerJobTitleRequest.update(parm.id, parm)
        }
      } else {
        return await workerJobTitleRequest.saveEdit(Object.assign({}, parm, {
          dataId: parm.id,
          id: undefined
        }))
      }
    },
    async addEdit (parm) {
      if (this.isReal) {
        return await workerJobTitleRequest.add(parm)
      } else {
        return await workerJobTitleRequest.addEdit(parm)
      }
    },
    dealDataInfo (data) {
      let jbData = this.$store.getters.jobTitleList.find(v => v.key === data.jobTitleId)
      if (jbData) {
        data.jobTitleType = jbData.data.type
        data.topLevel = jbData.data.topLevel
        data.mLevel = jbData.data.mLevel
        data.jobTitleTitle = jbData.data.title
      }
      return data
    },
    completeness (data) {
      this.$emit('completeness', data)
    },
    async save () {
      await this.$refs['worker_job_title_p'].save()
    },
    async submit () {
      await this.$refs['worker_job_title_p'].submit()
    },
    testEdited () {
      return this.$refs['worker_job_title_p'].testEdited()
    }
  },
  created () {
    this.$store.dispatch('loadJobTitleList')
  },
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          selectDatas: this.$store.getters.selectDatas['jobtitle_type_list'],
          label: '岗位专业',
          key: 'type',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          selectDatas: this.$store.getters.jobTitleList,
          label: '岗位',
          key: 'jobTitleId',
          check: {
            required: true
          },
          format: (data) => {
            return data.jobTitleType + ' ' + data.jobTitleTitle
          }
        },
        // {
        //   type: 'datePicker',
        //   label: '上岗时间',
        //   key: 'startDate',
        //   check: {
        //     required: true
        //   }
        // },
        // {
        //   type: 'datePicker',
        //   label: '离任时间',
        //   key: 'endDate'
        // },
        {
          type: 'datePicker',
          label: '聘任起始时间',
          key: 'hireStartDate',
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '聘任终止时间',
          key: 'hireEndDate'
        },
        {
          type: 'input',
          label: '在岗单位',
          key: 'hisName'
        }]
        return data
      }
    },
    columnList () {
      return [{
        title: '岗位专业',
        sort: true,
        field: 'type'
      },
      {
        title: '岗位类型',
        sort: true,
        field: 'jobTitleType'
      },
      {
        title: '岗位层级',
        sort: true,
        field: 'topLevel'
      },
      {
        title: '职称级别',
        sort: true,
        field: 'mLevel'
      },
      {
        title: '岗位等级',
        sort: true,
        field: 'jobTitleTitle'
      },
      // {
      //   title: '上岗时间',
      //   sort: true,
      //   field: 'startDate',
      //   width: 130,
      //   dataType: Date,
      //   format: (rowData) => {
      //     return rowData && rowData.startDate ? rowData.startDate.slice(0, 10) : '-'
      //   }
      // },
      // {
      //   title: '离任时间',
      //   sort: true,
      //   field: 'endDate',
      //   width: 130,
      //   dataType: Date,
      //   format: (rowData) => {
      //     return rowData && rowData.endDate ? rowData.endDate.slice(0, 10) : '至今'
      //   }
      // },
      {
        title: '聘任起始时间',
        sort: true,
        field: 'hireStartDate',
        width: 130,
        dataType: Date,
        format: (rowData) => {
          return rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-'
        }
      },
      {
        title: '聘任终止时间',
        sort: true,
        field: 'hireEndDate',
        width: 130,
        dataType: Date,
        format: (rowData) => {
          return rowData && rowData.hireEndDate ? rowData.hireEndDate.slice(0, 10) : '至今'
        }
      },
      {
        title: '在岗单位',
        sort: true,
        field: 'hisName'
      },
      {
        title: '累计聘任上岗年限',
        sort: true,
        field: 'years',
        dataType: Number
      }]
    }
  }
}
</script>

<template>
  <modify-apply-block
    :column-list="columnList"
    ref="worker_certificate_pro_qua_p"
    :display="display"
    :form-parms-add="formParmsAdd"
    :table-actions="tableActions"
    :dataFileParm="{pDirPath: '/worker_certificate/'}"
    block-name="专业技术资格证书"
    :getEdit="getEdit"
    :isReal="isReal"
    :saveEdit="saveEdit"
    :addEdit="addEdit"
    :init-add-form-data="initAddFormData"
    :deal-data-info="dealDataInfo"
    @completeness="completeness"
    ref-name="worker_certificate_pro_qua"
    :worker-id="workerId"
    :is-absolut="isAbsolut">
  </modify-apply-block>
</template>

<script>
import ModifyApplyBlock from './modifyApplyBlock'

import {
  workerCertificateRequest
} from '@/api'

export default {
  components: {
    ModifyApplyBlock
  },
  props: {
    isReal: {
      type: Boolean,
      default: false
    },
    isAbsolut: {
      type: Boolean,
      default: true
    },
    workerId: {
      type: [String, Number]
    },
    display: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    async getEdit () {
      if (this.isReal) {
        let data = await workerCertificateRequest.get({
          type: 'pro_qua',
          workerId: this.workerId
        })
        return data.map(v => {
          return {
            sourceInfo: v
          }
        })
      } else {
        return await workerCertificateRequest.getEdit({
          type: 'pro_qua',
          workerId: this.workerId
        })
      }
    },
    async saveEdit (parm) {
      if (this.isReal) {
        if (parm.isDel) {
          return await workerCertificateRequest.del(parm.id)
        } else {
          return await workerCertificateRequest.update(parm.id, parm)
        }
      } else {
        return await workerCertificateRequest.saveEdit(Object.assign({}, parm, {
          dataId: parm.id,
          id: undefined
        }))
      }
    },
    async addEdit (parm) {
      if (this.isReal) {
        return await workerCertificateRequest.add(parm)
      } else {
        return await workerCertificateRequest.addEdit(parm)
      }
    },
    initAddFormData () {
      return {
        workerId: this.workerId,
        type: 'pro_qua'
      }
    },
    dealDataInfo (data) {
      let jbData = this.$store.getters.certificateList.find(v => v.key === data.certificateId)
      if (jbData) {
        data.certificateTitle = jbData.data.title
      }
      return data
    },
    completeness (data) {
      this.$emit('completeness', data)
    },
    async save () {
      await this.$refs['worker_certificate_pro_qua_p'].save()
    },
    async submit () {
      await this.$refs['worker_certificate_pro_qua_p'].submit()
    },
    testEdited () {
      return this.$refs['worker_certificate_pro_qua_p'].testEdited()
    }
  },
  created () {
    this.$store.dispatch('loadCertificateList')
  },
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '证书编码',
          key: 'code'
        },
        // {
        //   type: 'select',
        //   selectDatas: this.$store.getters.workerList,
        //   label: '所属员工',
        //   key: 'workerId',
        //   check: {
        //     required: true
        //   }
        // },
        {
          type: 'input',
          label: '系列名称/类别',
          key: 'xlmc'
        },
        {
          type: 'input',
          label: '专业名称',
          key: 'pro'
        },
        {
          type: 'select',
          selectDatas: this.$store.getters.certificateList,
          label: '资格证书',
          key: 'certificateId',
          check: {
            required: true
          },
          format: (data) => {
            return data.certificateTitle
          }
        },
        // {
        //   type: 'input',
        //   label: '管理号',
        //   key: 'gradSchool'
        // },
        {
          type: 'datePicker',
          label: '授予(批准)时间',
          key: 'getDate'
        },
        {
          type: 'input',
          label: '批准文号',
          key: 'practiceType'
        },
        {
          type: 'datePicker',
          label: '发证时间',
          key: 'hireStartDate'
        }]
        return data
      }
    },
    tableActions () {
      return [{
        key: 'draft_file',
        label: '扫描件',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'table-expand-edit',
        label: '修改',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'del',
        label: '删除',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'back_del',
        label: '恢复',
        show: (data) => {
          return data.isDel
        }
      }]
    },
    columnList () {
      return [{
      //   title: '编号',
      //   fixed: 'left',
      //   field: 'id'
      // },
      // {
        title: '证书编码',
        field: 'code'
      },
      // {
      //   title: '管理号',
      //   field: 'gradSchool'
      // },
      // {
      //   title: '工作单位',
      //   field: 'workerOrg'
      // },
      {
        title: '系列名称/类别',
        field: 'xlmc'
      },
      {
        title: '专业名称',
        field: 'pro'
      },
      {
        title: '资格名称',
        field: 'certificateTitle'
      },
      {
        title: '授予(批准)时间',
        field: 'getDate',
        width: 120,
        format: (rowData) => {
          return rowData && rowData.getDate ? rowData.getDate.slice(0, 10) : '-'
        }
      },
      {
        title: '批准文号',
        field: 'practiceType'
      },
      {
        title: '发证时间',
        field: 'hireStartDate',
        width: 120,
        format: (rowData) => {
          return rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-'
        }
      }]
    }
  }
}
</script>
